:root {
    --primary: #e7032c;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    scroll-margin-top: 50px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
}

::-webkit-scrollbar {
    height: 5px;
}
