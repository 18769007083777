@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GothamHTF';
    src: url('./assets//fonts//GothamHTF-Medium.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'GothamHTF';
    src: url('./assets//fonts//GothamHTF-Bold.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'GothamHTF';
    src: url('./assets//fonts//GothamHTF-Book.otf');
}

@layer base {
    html {
        font-family: GothamHTF, system-ui, sans-serif;
    }
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(162, 163, 165, 0.9);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

